import { useVariables } from '@conte-ltd/components-theme'
import { css } from '@emotion/react'
import * as React from 'react'
import { Button, button } from '../components/button'
import { Layout } from '../components/layout'
import { Theme } from '../styles/theme'

const NotFoundPage = () => {
  const title = 'お探しのページが見つかりません'
  const { color, fontSize } = useVariables<Theme>()

  return (
    <Layout>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 6rem;
          width: 100vw;
          height: 100vh;
        `}
      >
        <title>{title}</title>
        <h1
          css={css`
            font-size: ${fontSize.xl};
          `}
        >
          {title}
        </h1>
        <p>
          <Button href={'/'} css={button(color).secondary}>
            トップページに戻る
          </Button>
        </p>
      </div>
    </Layout>
  )
}

export default NotFoundPage
